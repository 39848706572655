import type { Build } from './environments/environment.type';

export const bfNamespace = '__BF_MFS__';
export const nfNamespace = '__NATIVE_FEDERATION__';

type VersionMap = {
    [remote: string]: string;
};

type PackageVersions = {
    [packageName: string]: VersionMap;
};

export function initMFs() {
    window[bfNamespace] ??= {};

    window.registerMF = (buildInfo: Build) => {
        window[bfNamespace][buildInfo.app] = buildInfo;
    };
}

export function getMFInfo(name: string): Build | undefined {
    return window[bfNamespace][name];
}

export function registerMFDebug() {
    window.debug = () => {
        // https://github.com/angular-architects/module-federation-plugin/blob/main/libs/native-federation-runtime/src/lib/model/global-cache.ts
        const NF_CACHE = window[nfNamespace];
        if (!NF_CACHE) {
            throw new Error('Native Federation cache not found');
        }

        // Create a styled header
        console.groupCollapsed(
            '%cModule Federation Debug Info',
            'font-size: 16px; font-weight: bold; color: #2196F3'
        );
        console.log(NF_CACHE);
        console.groupEnd();
        console.log('Run `debug()` again once a remote has registered to see the build info');

        const packageVersions: PackageVersions = {};

        for (const [name, remote] of NF_CACHE.remoteNamesToRemote.entries()) {
            console.groupCollapsed(`%c${name}`, 'color: #4CAF50; font-weight: bold');

            remote.shared.forEach(({ packageName, version }) => {
                if (!packageVersions[packageName]) {
                    packageVersions[packageName] = {};
                }
                if (version) {
                    packageVersions[packageName][name] = version;
                }
            });

            const bfPackages = remote.shared
                .filter(s => s.packageName.startsWith('@bannerflow'))
                .reduce(
                    (acc, pkg) => ({
                        ...acc,
                        [pkg.packageName]: pkg.version ?? 'N/A'
                    }),
                    {}
                );

            // Print remote details
            const build = getMFInfo(name);
            const info = build
                ? {
                      Date: build.date,
                      Git: `branch: ${build.branch}, commit: ${build.commit}`
                  }
                : {};
            console.table({
                'Remote URL': remote.baseUrl,
                ...info,
                'Total Exposed Modules': remote.exposes.length,
                '@angular/core':
                    remote.shared.find(s => s.packageName === '@angular/core')?.version ?? 'N/A',
                ...bfPackages
            });

            if (remote.exposes.length) {
                console.groupCollapsed('Exposed Modules');
                console.table(
                    remote.exposes.map(({ key, outFileName }) => ({
                        Module: key,
                        File: outFileName
                    }))
                );
                console.groupEnd();
            }

            console.groupEnd();
        }

        // Check for version mismatches
        const mismatches = Object.entries(packageVersions)
            .filter(([_, versions]) => {
                const uniqueVersions = new Set(Object.values(versions));
                return uniqueVersions.size > 1;
            })
            .map(([pkg, versions]) => ({
                Package: pkg,
                ...versions
            }));

        if (mismatches.length) {
            console.group('%cVersion Mismatches', 'color: #F44336; font-weight: bold');
            console.table(mismatches);
            console.log('All versions: ', packageVersions);
            console.groupEnd();
        } else {
            console.log('%c✓ No version mismatches found', 'color: #4CAF50');
        }
    };

    window.debug();
}

export function getMFs() {
    const NF_CACHE = window[nfNamespace];
    if (!NF_CACHE) {
        throw new Error('Native Federation cache not found');
    }
    return NF_CACHE.remoteNamesToRemote.entries();
}
