import { Environment } from './environment.type';
import defaults from './environment.defaults';

export const environment: Environment = {
    ...defaults,
    STAGE: 'production',
    MFE_MANIFEST_URL: 'federation.manifest.production.json',
    LOGIN_URL: 'https://login.bannerflow.com',
    CLIENT_ID: 'M55CVSHPOWWmKXC1wXYDDjkW86EmATQb',
    CS_ORIGIN: 'https://api.bannerflow.com/cs',
    AD_SERVICE_ORIGIN: 'https://c.bannerflow.net/a',
    API_URLS: ['https://am.bannerflow.com', 'https://api.bannerflow.com'],

    REFRESH_TOKEN_LOCAL_STORAGE: true
};
